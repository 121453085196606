const productionServer = false;
const isProduction =
  process.env.NODE_ENV === "production" && productionServer ? true : false;

export const URL = isProduction
  ? "https://formyeveroffice.com"
  : "https://test.formyeveroffice.com/api";
export const API_URL = isProduction ? `${URL}/api/` : `${URL}/api/`;
export const IMAGE_URL = isProduction ? `${URL}/assets/` : `${URL}/assets/`;
export const PAYMENT_URL = isProduction
  ? "https://secure.przelewy24.pl/trnDirect"
  : "https://sandbox.przelewy24.pl/trnDirect";


export const GOOGLE_CALENDAR_SCOPES =
  "https://www.googleapis.com/auth/calendar";
export const GOOGLE_CALENDAR_DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
export const GOOGLE_CALENDAR_CLIENT_ID =
  "725932002379-b6vdellrnncrt6ncp1lv2clq3b5m2ean.apps.googleusercontent.com";
export const GOOGLE_CALENDAR_API_KEY =
  "AIzaSyC82HxvGF9QaxMMlqW8oY5eMXTARCO6aKQ";

export const VERSION = 4.57;
